import React from "react";
import { MdOutlineDashboard } from "react-icons/md";
import {
  AiOutlineDown,
  AiOutlineAppstore,
  AiOutlineGold,
} from "react-icons/ai";
import { TfiRulerPencil } from "react-icons/tfi";
import { TbStack2 } from "react-icons/tb";
import { BsClipboardPlus } from "react-icons/bs";
import { FcMoneyTransfer } from "react-icons/fc";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function AdminSecondNavbar() {
  const navigate = useNavigate();

  return (
    <div className="adminDesktopSecondNavbarMainBox">
      <div className="adminDesktopSecondNavbarItemsBox">
        <AiOutlineGold size={"20px"} />
        {/* <FcMoneyTransfer size={"20px"} /> */}
        <p>Purchase</p>
        {/* <AiOutlineDown /> */}
      </div>
      <div
        onClick={() => {
          navigate("/add_bulk_product");
        }}
        className="adminDesktopSecondNavbarItemsBox"
      >
        <BsClipboardPlus size={"17px"} />
        <p>Add Stock</p>
        {/* <AiOutlineDown /> */}
      </div>
      <div
        onClick={() => navigate("/admin_invoice")}
        className="adminDesktopSecondNavbarItemsBox"
      >
        <FaFileInvoiceDollar size={"17px"} />
        <p>Invoice</p>
        {/* <AiOutlineDown /> */}
      </div>
      <div className="adminDesktopSecondNavbarItemsBox">
        <RiCoupon3Line size={"17px"} />
        <p>Voucher</p>
        {/* <AiOutlineDown /> */}
      </div>
      <div className="adminDesktopSecondNavbarDropdownReportsBox">
        <div className="adminDesktopSecondNavbarItemsBox">
          <HiOutlineDocumentReport size={"17px"} />
          <p>Reports</p>
          <AiOutlineDown />
        </div>
        <div className="adminDesktopSecondNavbarDropdownReportsItemsMainBox">
          <p>Hello</p>
        </div>
      </div>
    </div>
  );
}
