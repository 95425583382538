import React, { useEffect, useState } from "react";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import AdminHeading from "../Heading/AdminHeading";
import { a38, a39, a45, a51, a52 } from "../../../Api/RootApiPath";
import { InfinitySpin } from "react-loader-spinner";
import { BsHandbag } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import logoImage from "../../../Images/loyalStringLogoSmall.png";
import { useSelector } from "react-redux";

export default function AdminAllOrders() {
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 25;

  const allStates = useSelector((state) => state);

  const navigate = useNavigate();
  useEffect(() => {
    fetch(a38)
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        setAllOrders(response.data);
        setLoading(false);
        // setOlddata(response);
        console.log(response.data);
      });
  }, []);
  function showPDFWithId(id) {
    // Make the API POST request with the ID
    fetch(a51, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob()) // Convert response to Blob
      .then((blob) => {
        // Create a URL for the Blob object
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, "_blank");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleOrderStatusChange = (orderNumber, newStatus) => {
    // Make the API request to update the order status
    const formData = {
      orderNumber: orderNumber,
      OrderStatus: newStatus,
    };
    fetch(a39, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // alert("changed");
        // Update the order status in the local state
        setAllOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.orderNumber === orderNumber
              ? { ...order, orderStatus: newStatus }
              : order
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // const removeUnpaid = (x) => {
  //   x.filter((y) => {
  //     if (y !== "Pending") {
  //       return [...y];
  //     }
  //   });
  //   return x;
  // };

  // const filterOrders = (x) => {
  //   allOrders.filter((y) => y.orderName === x);
  //   return setFilteredOrders(allOrders);
  // };
  const filterOrders = () => {
    let filtered = allOrders;

    if (orderStatus !== "") {
      filtered = filtered.filter((order) => order.orderStatus === orderStatus);
    }

    if (orderNumber !== "") {
      filtered = filtered.filter(
        (order) => order.orderNumber && order.orderNumber.includes(orderNumber)
      );
    }

    if (fromDate !== "" && toDate !== "") {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.createdOn);
        return orderDate >= new Date(fromDate) && orderDate <= new Date(toDate);
      });
    }

    setFilteredOrders(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  useEffect(() => {
    filterOrders();
    window.scrollTo(0, 0);

    console.log(filteredOrders);
  }, [orderStatus, orderNumber, allOrders, currentPage, fromDate, toDate]);

  useEffect(() => {}, [currentPage]);

  const indexOfLastProduct = currentPage * ordersPerPage;
  const indexOfFirstProduct = indexOfLastProduct - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const generateBillPDF = (
    // itemsList,
    x
    // totalAmount,
    // discount,
    // gst,
    // pricePaid
  ) => {
    const doc = new jsPDF({
      orientation: "landscape",
      format: "a5",
    });
    // let itemsListed = [x.tblProduct];
    // let itemsLists = orderItems;
    console.log(x);
    // Add logo and heading
    doc.addImage(logoImage, "PNG", 10, 10, 15, 10);
    doc.setFontSize(18);
    doc.text("N. D. Darbar Sindgi", 30, 15);
    doc.setFontSize(12);
    doc.text("Old SBI Road, SINDGI, Dist. Vijaypur (Karnataka)", 30, 22);
    doc.setFontSize(10);
    doc.text("Whastapp - 8123803806", 145, 14);
    doc.text("Mob - 7022308916", 155, 20);
    doc.setDrawColor(255, 0, 0);
    doc.line(10, 25, 200, 25);

    // Add items table
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(14);
    let y = 30;
    const creationDate = new Date(x.createdOn);
    doc.setFontSize(10);

    if (x.tblCustomerDetails) {
      doc.text(
        `Name - ${x.tblCustomerDetails.firstName} ${x.tblCustomerDetails.lastName}`,
        10,
        y
      );
      doc.text(`Mobile - ${x.tblCustomerDetails.mobile}`, 10, y + 6);
      doc.text(
        `Address - ${x.tblCustomerDetails.currAddStreet} ${x.tblCustomerDetails.currAddTown} ${x.tblCustomerDetails.currAddState} ${x.tblCustomerDetails.currAddPinCode}`,
        10,
        y + 12
      );
      doc.text(`Invoice No - ${x.invoiceNo}`, 130, y);
      doc.text(`Date - ${creationDate.toLocaleString()}`, 130, y + 6);
      doc.text(`Email - ${x.tblCustomerDetails.email}`, 130, y + 12);
    }

    doc.line(10, y + 15, 200, y + 15);
    doc.setFontSize(10);
    y = 50;
    doc.text("Items", 10, y);
    doc.text("HSN", 50, y);
    doc.text("Gr.Wt", 65, y);
    doc.text("Stone.Wt", 80, y);
    doc.text("Net.Wt", 100, y);
    doc.text("Proc.Wt", 115, y);
    doc.text("Total.Wt", 130, y);
    doc.text("Making", 145, y);
    doc.text("Rate:10/Gm", 160, y);
    doc.text("Price", 185, y);
    doc.line(10, y + 3, 200, y + 3);
    const maxPageHeight = doc.internal.pageSize.height - 20;
    y = 60; // Adjust starting Y position
    const columnWidth = 40;
    doc.setFontSize(8);
    orderItems.forEach((item) => {
      if (y + 10 > maxPageHeight) {
        doc.addPage();
        y = 30; // Reset Y position for the new page
      }
      item.productName
        ? doc.text(item.productName, 10, y)
        : doc.text("-", 10, y);
      item.hsnCode !== "null"
        ? doc.text(item.hsnCode, 50, y)
        : doc.text("-", 50, y);
      item.grosswt ? doc.text(item.grosswt, 65, y) : doc.text("-", 65, y);
      item.stoneWeight
        ? doc.text(item.stoneWeight, 80, y)
        : doc.text("-", 80, y);
      item.netWt ? doc.text(item.netWt, 100, y) : doc.text("-", 100, y);
      // doc.text(item.procWt, 115, y);
      // // doc.text(item.totalWt, 130, y);
      item.makingchrg
        ? doc.text(`${parseInt(item.makingchrg)}`, 145, y)
        : doc.text("", 145, y);
      item.rate
        ? doc.text(`${parseInt(item.rate)}`, 165, y)
        : doc.text("-", 160, y);
      item.price
        ? doc.text(`${parseInt(item.price)}`, 185, y)
        : doc.text("", 185, y);
      y += 8;
    });
    // Add total amount
    doc.line(10, y - 5, 200, y - 5);
    doc.text(`Sales Amount: ${x.price}`, 155, y);
    doc.text(`CGST 1.5%: ${parseFloat(x.govtTax) / 2}`, 155, y + 4);
    doc.text(`SGST 1.5%: ${parseFloat(x.govtTax) / 2}`, 155, y + 8);
    doc.text(`R.O./Discount: ${x.offer}`, 155, y + 12);
    doc.text(`Total: ${x.receivedAmt}`, 155, y + 16);
    doc.line(10, y + 20, 200, y + 18);

    // Add price total box
    // const priceTotalBoxX = 130;
    // const priceTotalBoxY = 180; // Adjust position
    // doc.rect(priceTotalBoxX, priceTotalBoxY, 60, 40);
    // doc.text("Price Total", priceTotalBoxX + 5, priceTotalBoxY + 5);
    // doc.text(`Discount: ${discount}`, priceTotalBoxX + 5, priceTotalBoxY + 15);
    // doc.text(`GST: ${gst}`, priceTotalBoxX + 5, priceTotalBoxY + 25);
    // doc.text(
    //   `Price Paid: ${pricePaid}`,
    //   priceTotalBoxX + 5,
    //   priceTotalBoxY + 35
    // );

    // Add footer with company branches
    const footerY = doc.internal.pageSize.height - 12;
    doc.setFontSize(10);
    doc.text("Other Branches:", 10, footerY);
    doc.text("Saraf Bazar: Vijayapur (Ph:251562)", 40, footerY);
    doc.text(
      "Darbar Square: Opposite Balaji Temple, Vijayapur (Ph: 221177)",
      40,
      footerY + 4
    );
    doc.text("www.darbarjewellers.com", 40, footerY + 8);

    // Save or display the PDF
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };
  // const itemsList = [
  //   {
  //     // doc.text("Items", 10, y);
  //     // doc.text("HSN", 50, y);
  //     // doc.text("Gr.Wt", 65, y);
  //     // doc.text("Stone.Wt", 80, y);
  //     // doc.text("Net.Wt", 100, y);
  //     // doc.text("Proc.Wt", 115, y);
  //     // doc.text("Total.Wt", 130, y);
  //     // doc.text("Making", 145, y);
  //     // doc.text("Rate:10/Gm", 160, y);
  //     // doc.text("Price", 185, y);
  //     product_Name: "Item 1",
  //     hsn: "10g",
  //     grossWt: "Gold",
  //     stoneWt: "95%",
  //     netWt: "$100",
  //     procWt: "$100",
  //     totalWt: "$100",
  //     making: "$100",
  //     rate: "$100",
  //     price: "$100",
  //   },
  //   {
  //     product_Name: "Item 2",
  //     hsn: "20g",
  //     grossWt: "Gold",
  //     stoneWt: "95%",
  //     netWt: "$200",
  //     procWt: "$200",
  //     totalWt: "$200",
  //     making: "$200",
  //     rate: "$200",
  //     price: "$200",
  //   },

  //   {
  //     product_Name: "Item 2",
  //     hsn: "20g",
  //     grossWt: "Gold",
  //     stoneWt: "95%",
  //     netWt: "$200",
  //     procWt: "$200",
  //     totalWt: "$200",
  //     making: "$200",
  //     rate: "$200",
  //     price: "$200",
  //   },

  //   // Add more items here
  // ];
  const getAllOrderItems = async (id, x) => {
    fetch(a52, {
      method: "POST",
      body: JSON.stringify({ OrderId: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json()) // Convert response to Blob
      .then((data) => {
        setOrderItems(data.data);
        setLoading(false);
        console.log(orderItems, "Order Items");
        generateBillPDF(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (orderItems.length > 0) {
      generateBillPDF(orderItems);
    }
  }, [orderItems]);
  const totalAmount = "$1000";
  const discount = "$50";
  const gst = "$30";
  const pricePaid = "$980";

  return (
    <div>
      <AdminHeading />

      <div className="adminMainBodyBox">
        <AdminBreadCrump
          title={"All Orders"}
          companyName={"Loyalstring"}
          module={"E-commerce"}
          page={"All Orders"}
        />
        <div className="adminAddCategoryMainBox">
          <div
            style={{ marginBottom: "50px" }}
            className="adminAddCategoryInnerBox"
          >
            <div className={loading == true ? "loading" : "none"}>
              {/* <h1>Loading...</h1> */}
              <InfinitySpin width="200" color="#4fa94d" />
            </div>
            <div
              style={{ marginInline: "0px", paddingInline: "0px" }}
              className="adminAllOrdersFilterBox"
            >
              <div className="adminAllOrderLeftBox">
                <input
                  style={{ marginLeft: "0px", paddingLeft: "0px" }}
                  type="text"
                  placeholder="Search..."
                  value={orderNumber}
                  onChange={(e) => {
                    setOrderNumber(e.target.value.toUpperCase()),
                      setCurrentPage(1);
                  }}
                />
                <p>Status</p>
                <select
                  value={orderStatus}
                  onChange={(e) => {
                    setOrderStatus(e.target.value), setCurrentPage(1);
                  }}
                >
                  <option value="">Choose...</option>
                  <option value="Pending Payment">Pending Payment</option>
                  <option value="Payment Failed">Payment Failed</option>
                  <option value="Paid">Paid</option>
                  <option value="Shipped">Shipped</option>
                  <option value="Delivered">Delivered</option>
                </select>
                <input
                  type="date"
                  placeholder="From Date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <input
                  type="date"
                  placeholder="To Date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              {/* <div className="adminAllOrderRightBox">
                <button>
                  <BsHandbag style={{ marginRight: "5px" }} />
                  Add New Order
                </button>
                <button>Export</button>
              </div> */}
            </div>
            <div
              className="adminAllOrdersTableMainBox"
              style={{ overflow: "auto" }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Order No</th>
                    {/* <th>Customer Id</th> */}
                    <th>Customer Name</th>
                    <th>Price</th>
                    {/* <th>Product Id</th> */}
                    <th>Product Name</th>
                    <th>Item Code</th>
                    {/* <th>Quantity</th> */}
                    <th>Amount</th>
                    <th>Order Status</th>
                    {/* <th>Payment Mode</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.reverse().map((x) => {
                    let itemsList = [x.tblProduct[0]]; // Use equal sign (=) here
                    return (
                      // <tr key={x.Customer_id}>
                      <tr key={x.id}>
                        <td>{x.id}</td>
                        <td
                          onClick={() => {
                            navigate(`/admin-orderdetails/${x.id}`);
                          }}
                          className="adminAllOrdersOrderData"
                        >
                          {x.orderNumber}
                        </td>
                        {/* <td>{x.customer_Id}</td> */}
                        {/* NOTE:"Please Uncomment bekow line" */}
                        {/* <td>
                      {x.tblCustomerDetails.firstName +
                        " " +
                        x.tblCustomerDetails.lastName}
                    </td> */}
                        <td>{x.price}</td>
                        {/* <td>{x.product_id}</td> */}
                        <td>{x.tblProduct.product_Name}</td>
                        <td
                          className="adminAllOrdersOrderData"
                          onClick={() => {
                            navigate(`/admin-orderdetails/${x.id}`);
                          }}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {x.tblProduct.itemCode}
                        </td>

                        {/* <td>{x.qty}</td> */}
                        <td>{x.receivedAmt}</td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <p
                            style={
                              x.orderStatus === "Paid"
                                ? {
                                    backgroundColor: "rgba(0, 128, 0, 0.7)",
                                    color: "white",
                                    borderRadius: "5px",
                                    padding: "2px",
                                  }
                                : x.orderStatus === "Processing Payment"
                                ? {
                                    backgroundColor: "rgb(219, 153, 30)",
                                    color: "white",
                                    borderRadius: "5px",
                                    padding: "2px 10px",
                                  }
                                : x.orderStatus === "Payment Failed"
                                ? {
                                    backgroundColor: "rgb(231, 30, 60)",
                                    color: "white",
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                  }
                                : x.orderStatus === "Shipped"
                                ? {
                                    backgroundColor: "rgb(77, 155, 228)",
                                    color: "white",
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                  }
                                : x.orderStatus === "Delivered"
                                ? {
                                    backgroundColor: "rgb(159, 77, 206)",
                                    color: "white",
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                  }
                                : {
                                    backgroundColor: "rgb(180, 180, 46)",
                                    color: "white",
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                  }
                            }
                          >
                            {x.orderStatus}
                          </p>
                        </td>
                        {/* <td>{x.orderStatus.filter((y) => y !== "Pending")}</td> */}
                        {/* <td>{x.paymentMode}</td> */}
                        <td>
                          {x.orderStatus === "Paid" ? (
                            <select
                              required="required"
                              value={orderStatus}
                              onChange={(e) =>
                                handleOrderStatusChange(
                                  x.orderNumber,
                                  e.target.value
                                )
                              }
                            >
                              <option value={x.orderStatus}>Change..</option>
                              {/* <option value="Pending Payment">Pending Payment</option> */}
                              {/* <option value="Payment Failed">Payment Failed</option>
                        <option value="Paid">Paid</option> */}
                              <option value="Shipped">Shipped</option>
                              <option value="Delivered">Delivered</option>
                            </select>
                          ) : x.orderStatus === "Shipped" ? (
                            <select
                              required="required"
                              value={orderStatus}
                              onChange={(e) =>
                                handleOrderStatusChange(
                                  x.orderNumber,
                                  e.target.value
                                )
                              }
                            >
                              <option value={x.orderStatus}>Change..</option>
                              <option value="Delivered">Delivered</option>
                            </select>
                          ) : (
                            <p
                              style={
                                x.orderStatus === "Paid"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgba(0, 128, 0, 0.7)",
                                      whiteSpace: "nowrap",
                                    }
                                  : x.orderStatus === "Processing Payment"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgb(219, 153, 30)",
                                      whiteSpace: "nowrap",
                                    }
                                  : x.orderStatus === "Payment Failed"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgb(231, 30, 60)",
                                      whiteSpace: "nowrap",
                                    }
                                  : x.orderStatus === "Shipped"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgb(77, 155, 228)",
                                      whiteSpace: "nowrap",
                                    }
                                  : x.orderStatus === "Delivered"
                                  ? {
                                      fontWeight: "bold",
                                      color: "rgb(159, 77, 206)",
                                      whiteSpace: "nowrap",
                                    }
                                  : {
                                      fontWeight: "bold",
                                      color: "rgb(180, 180, 46)",
                                      whiteSpace: "nowrap",
                                    }
                              }
                            >
                              {x.orderStatus}
                            </p>
                          )}
                        </td>
                        <td>
                          <button
                            style={{ padding: "0px", cursor: "pointer" }}
                            onClick={() => {
                              // showPDFWithId(x.id), setLoading(true);
                              // generateBillPDF(x);
                              // console.log("order", [x.tblProduct]);
                              getAllOrderItems(x.id, x);
                            }}
                          >
                            show bill
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="bulkProductAddingTableMain">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                  Previous
                </button>
                <button
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
